<template>
  <div class="mb-50 mb-sm-90 width--full d-flex flex-column align-center">
    <div class="mb-6 titleMigration--text font-title-migration">
      dialics upgrate 2.0
    </div>

    <div class="mb-30 mb-sm-40 text-center descriptionMigration--text font-normal migration-guide-main__description">
      We'll help you navigate your migration journey with easy-to-follow steps and provide support you need at each stage.
    </div>

    <div class="width--full migration-guide-main__video">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/WRjpHdpqgDo?si=D4wTEMpuj6ov3d-P"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'MigrationGuideMain',
}
</script>

<style scoped lang="scss">

.migration-guide-main {

  &__description {
    max-width: 620px;
  }

  &__video {
    border-radius: 10px;
    height: 214px;
    overflow: hidden;


    @media screen and (min-width: 600px) {
      height: 542px;
    }

    @media screen and (min-width: 1024px) {
      height: 654px;
    }
  }
}
</style>