<template>
  <div class="pt-22 pt-sm-36 px-12 px-sm-28 d-flex flex-column align-center">
    <img
      class="migration-guide-steps-card-two__img"
      src="/images/process.png"
    >

    <div class="mb-5 mb-sm-9 mt-10 font-xs-migration titleMigration--text">
      PROCESSING...
    </div>

    <div class="font-xxs-migration descriptionMigration--text text-center">
      It will take about 20 minutes. Feel free to close this page, the migration will continue. We'll drop you an email once everything's set up and ready
    </div>
  </div>
</template>

<script>
export default {
  name: 'MigrationGuideStepsCardTwo',
}
</script>

<style scoped lang="scss">

.migration-guide-steps-card-two {

  &__img {
    width: 26px;
    height: 26px;

    @media screen and (min-width: 600px) {
      width: 56px;
      height: 56px;
    }
  }
}
</style>