<template>
  <div class="mb-50 mb-sm-90 width--full d-flex flex-column align-center">
    <div class="mb-30 mb-sm-40 titleMigration--text font-title-migration text-center">
      We can’t migrate call logs and reporting
    </div>

    <MigrationGuideCallLogTopCard
      v-for="item in topList"
      :key="item.id"
      :item="item"
    >
      <template #icon-4>
        <v-icon size="32" color="primary">$flashIcon</v-icon>
      </template>

      <template #text-4>
        Inside the updated Dialiсs you can view the data from old version with a single button click. You can also
        <span class="primary--text font-normal">Download Call logs and Reporting</span>
        now or later after upgrading until {{ dateEnd }}.
      </template>

      <template #icon-5>
        <v-icon size="32" color="primary">$attentionIcon</v-icon>
      </template>

      <template #text-5>
          You will not be able to manage or edit anything after the update.
      </template>
    </MigrationGuideCallLogTopCard>

    <div class="mb-12 mb-sm-20 titleMigration--text font-subtitle-migration">
      Why Can't We Migrate Call Logs?
    </div>

    <MigrationGuideCallLogBottomCard
      v-for="item in bottomList"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script>

import MigrationGuideCallLogTopCard
  from '@/views/settings/MigrationGuide/sections/MigrationGuideCallLog/MigrationGuideCallLogTopCard.vue'
import MigrationGuideCallLogBottomCard
  from '@/views/settings/MigrationGuide/sections/MigrationGuideCallLog/MigrationGuideCallLogBottomCard.vue'

import { ACCOUNT_MIGRATION_DATE_END } from '@/constants/accountMigrationStatus'

export default {
  name: 'MigrationGuideCallLog',
  components: { MigrationGuideCallLogBottomCard, MigrationGuideCallLogTopCard },
  data: () => ({
    dateEnd: ACCOUNT_MIGRATION_DATE_END.dateOne,
    topList: [
      {
        id: 4,
        title: 'But don\'t worry',
        description: 'With a single click, you can easily access your old Сall logs and Reporting'
      },
      {
        id: 5,
        title: 'Please, pay attention',
        description: 'Managing and editing will not be available in the old version'
      }
    ],
    bottomList: [
      {
        id: 1,
        title: 'New System, New Rules',
        description: `Rest assured that, until ${ACCOUNT_MIGRATION_DATE_END.dateOne}, you can seamlessly switch back to the old version if you need to access your legacy call logs and review reports.`
      },
      {
        id: 2,
        title: 'Data Privacy',
        description: 'To keep your information safe, we don\'t move old call logs. This is in line with privacy laws and our own data protection policy.'
      },
      {
        id: 3,
        title: 'Start Fresh, Less Clutter',
        description: 'Not moving old logs means your new Dialics 2.0 experience starts fresh, making it easier to navigate and understand your new call data.'
      }
    ],
  })
}
</script>