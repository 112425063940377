<template>
  <div class="mb-30 mb-sm-40 migration-guide-call-log-top-card">
    <div class="mb-12 titleMigration--text font-subtitle-migration text-center">{{ item.title }}</div>

    <div class="px-16 py-16 py-sm-20 px-sm-42 migration-guide-call-log-top-card-block">
      <div class="mb-14 d-flex justify-center">
        <slot :name="`icon-${item.id}`"></slot>
      </div>

      <div class="mb-10 titleMigration--text font-large-capitalize text-center">
        {{ item.description }}
      </div>

      <div class="text-center infoTextProcessMigration--text font-regular">
        <slot :name="`text-${item.id}`"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MigrationGuideCallLogTopCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="scss">
.migration-guide-call-log-top-card {
  max-width: 720px;
  width: 100%;
}

.migration-guide-call-log-top-card-block {
  border-radius: 12px;
  background: var(--v-bgStepMigration-base);
}
</style>