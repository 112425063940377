<template>
  <div class="migration-guide-progress">
    <div class="my-15 migration-guide-progress__border"/>

    <div class="width--full px-25 py-25 d-flex flex-column flex-sm-row align-center justify-space-between migration-guide-progress__container">
      <CircleProgress/>

      <div class="ml-0 ml-sm-30 mt-30 mt-sm-0 text-center text-sm-left">
        <div class="mb-10 primary--text migration-guide-progress__days">DAYS</div>

        <div class="titleMigration--text font-large-medium">Left until the old version is no longer supported - {{ ACCOUNT_MIGRATION_DATE_END }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleProgress from '@/views/settings/MigrationGuide/sections/MigrationGuideProgress/CircleProgress.vue'

import { ACCOUNT_MIGRATION_DATE_END } from '@/constants/accountMigrationStatus'

export default {
  name: 'MigrationGuideProgress',
  components: { CircleProgress },
  data: () => ({
    ACCOUNT_MIGRATION_DATE_END: ACCOUNT_MIGRATION_DATE_END.dateTwo
  }),
}
</script>

<style scoped lang="scss">

.migration-guide-progress {
  @media screen and (min-width: 600px) {
    max-width: 720px;
  }

  &__container {
    border-radius: 20px;
    background: var(--v-bgStepMigration-base);
  }

  &__border {
    height: 1px;
    background: var(--v-borderGray-base);
  }

  &__days {
    font-size: 48px;
    font-weight: 900;
    letter-spacing: 3.84px;
    line-height: 57.6px;
  }
}

</style>