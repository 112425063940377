<template>
  <div class="mx-auto py-30 py-sm-40 px-8 px-sm-15 px-md-40 d-flex flex-column align-center migration-guide">
    <MigrationGuideMain/>

    <MigrationGuideSteps/>

    <MigrationGuideCallLog/>

    <MigrationGuideMigrate/>

    <MigrationGuideReady/>

    <MigrationGuideProgress/>
  </div>
</template>

<script>

import MigrationGuideMain from '@/views/settings/MigrationGuide/sections/MigrationGuideMain.vue'
import MigrationGuideSteps from '@/views/settings/MigrationGuide/sections/MigrationGuideSteps/MigrationGuideSteps.vue'
import MigrationGuideCallLog from '@/views/settings/MigrationGuide/sections/MigrationGuideCallLog/MigrationGuideCallLog.vue'
import MigrationGuideMigrate from '@/views/settings/MigrationGuide/sections/MigrationGuideMigrate/MigrationGuideMigrate.vue'
import MigrationGuideReady from '@/views/settings/MigrationGuide/sections/MigrationGuideReady.vue'
import MigrationGuideProgress from '@/views/settings/MigrationGuide/sections/MigrationGuideProgress/MigrationGuideProgress.vue'

export default {
  name: 'MigrationGuide',
  components: {
    MigrationGuideProgress,
    MigrationGuideCallLog,
    MigrationGuideSteps,
    MigrationGuideMain,
    MigrationGuideMigrate,
    MigrationGuideReady
  },
}
</script>

<style scoped lang="scss">

.migration-guide {
  max-width: 1400px;
}
</style>