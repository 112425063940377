<template>
  <svgProgress
    :value="value"
    :options="options"
  />
</template>

<script>
import svgProgress from 'svg-progress-bar'

import { dateFormatDistanceForSupport } from '@/helper/app-helper'
export default {
  name: 'CircleProgress',
  components: {
    svgProgress
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    },
    pathColors() {
      if (this.isDark) {
        return ['#443C5C', '#6F4FCC']
      }

      return ['#BEA8FF', '#6F4FCC']
    },
    options() {
      return {
        radius: 100,
        circleLineCap: 'round',
        varyStrokeArray: [15, 15],
        pathColors: this.pathColors,
        textColor: '#6F4FCC',
        text: function (value) {
          return `<span style="font-size: 64px; font-weight: 700">${this.htmlifyNumber(value)}</span>`
        },
      }
    },
    value() {
      const result = dateFormatDistanceForSupport()
      const [number] = result.split(' ')

      return Number(number)
    }
  }
}

</script>