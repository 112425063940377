<template>
  <v-checkbox
    v-bind="$attrs"
    v-on="$listeners"
    v-model="checkboxValue"
    hide-details
    :ripple="false"
    on-icon="mdi-check-box-outline"
    indeterminate-icon="mdi-minus-box-outline"
  >
    <template #label>
      <div class="checkboxTextColor--text">
        {{ label }}
      </div>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: 'PrimaryCheckbox',
  inheritAttrs: false,
  props: {
    value:  {
      type: Boolean,
      default: false
    },
    label: {
      type: String | Number,
      default: ''
    }
  },
  computed: {
    checkboxValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data: () => ({}),
  mounted() {},
  methods: {}
}
</script>

<style lang="scss">
@import '../../sass/var';
  
.v-input.v-input--selection-controls.v-input--checkbox {
  margin-top: 0!important;
  padding-top: 0!important;
  
  & > .v-input__control > .v-input__slot > .v-input--selection-controls__input {
    margin-right: 12px;
    height: 20px;
    width: 20px;
    
    & > i.v-icon {
      font-size: 20px;
    }
  }
  
  &:not(.v-input--is-label-active) > .v-input__control > .v-input__slot {
    & > .v-input--selection-controls__input > i.v-icon {
      color: $checkboxColor!important;
    }
  }
}
</style>
