<template>
  <div class="px-7 pt-10 px-sm-20 pt-sm-20 pt-md-30 d-flex flex-column flex-sm-row align-start justify-space-between migration-guide-steps-card">
    <div class="mb-14 mb-sm-0 mr-0 mr-sm-10 d-flex align-center migration-guide-steps-card-left">
      <div class="mr-10 titleMigration--text migration-guide-steps-card-left__number">{{ item.id }}</div>

      <div>
        <div class="mb-6 font-large titleMigration--text text-uppercase">{{ item.title }}</div>

        <div class="font-regular infoTextProcessMigration--text">{{ item.description }}</div>
      </div>
    </div>

    <div class="migration-guide-steps-card-right">
        <slot :name="`name-${item.id}`"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MigrationGuideStepsCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
}
</script>

<style scoped lang="scss">

.migration-guide-steps-card {
  height: 308px;
  border-radius: 10px;
  background: var(--v-bgStepMigration-base);

  @media screen and (min-width: 600px) {
    height: 360px;
  }

  & + & {
    margin-top: 40px;
  }
}

.migration-guide-steps-card-left {
  @media screen and (min-width: 600px) {
    width: 310px;
  }

  &__number {
    font-size: 60px;
    line-height: 90px;
    font-weight: 700;

    @media screen and (min-width: 600px) {
      font-size: 80px;
      line-height: 120px;
    }
  }
}

.migration-guide-steps-card-right {
  height: 100%;
  max-width: 100%;
  width: 100%;
  background: var(--v-bgStepContentMigration-base);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @media screen and (min-width: 1024px) {
    max-width: 550px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 600px;
  }
}
</style>