<template>
  <div class="width--full d-flex flex-column justify-center">
    <div class="mb-6 titleMigration--text font-title-migration text-center">
      Ready for a Smooth Migration?
    </div>

    <div class="mb-20 font-normal descriptionMigration--text text-center">
      With Dialics 2.0, you're getting a better, faster service with tons of new features.

      <div class="d-inline d-sm-block">
        Click below to make the move!
      </div>
    </div>

    <div class="d-flex justify-center">
      <ActionButton
        class="mr-10"
        @click="goToStartMigration"
      >
        Start migration
      </ActionButton>

      <CancelButton @click="openSupport">
        Talk to support
      </CancelButton>
    </div>
  </div>
</template>

<script>

import ActionButton from '@/components/buttons/ActionButton.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'

export default {
  name: 'MigrationGuideReady',
  components: { CancelButton, ActionButton },
  methods: {
    goToStartMigration() {
      this.$router.push({ name: 'MigrationProcess' })
    },
    openSupport() {
      if (window.$crisp) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
      }
    }
  }
}
</script>