<template>
  <div class="px-16 py-16 migration-guide-call-log-bottom-card">
    <div class="mb-10 titleMigration--text font-large text-center">{{ item.title }}</div>

    <div class="text-center descriptionMigration--text font-regular">
      {{ item.description }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'MigrationGuideCallLogBottomCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="scss">
.migration-guide-call-log-bottom-card {
  max-width: 720px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--v-borderGray-base);

  & + & {
    margin-top: 24px;

    @media screen and (min-width: 600px) {
      margin-top: 32px;
    }
  }
}
</style>