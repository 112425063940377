<template>
  <div class="pt-22 pt-sm-36 px-12 px-sm-28 d-flex flex-column align-center">
    <v-icon :size="checkSm ? 54 : 34">$cloudSuccessIcon</v-icon>

    <div class="mb-5 mb-sm-9 mt-6 font-xs-migration titleMigration--text">
      Migration successful!
    </div>

    <div class="font-xxs-migration descriptionMigration--text text-center">
      Congratulations, your data has been successfully migrated to Dialics 2.0! You're now ready to enjoy all the new features and improvements
    </div>
  </div>
</template>

<script>
export default {
  name: 'MigrationGuideStepsCardThree',
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width > 600
    }
  }
}
</script>