<template>
  <div class="pt-9 pt-sm-14 px-18 px-sm-29">
    <div class="mb-5 mb-sm-9 font-xs-migration titleMigration--text">
      Migration settings
    </div>

    <div class="mb-9 mb-sm-16 font-xxs-migration descriptionMigration--text">
      If you want to move all your data, pick "All data." If you prefer a fresh start, go with "Account"
    </div>

    <ToggleButton
      class="migration-guide-steps-card-one__buttons"
      :value="activeTab"
      :buttons="buttons"
      :height="checkSm ? 38 : 24"
      fontClass="font-xxs-migration"
    />

    <div class="mt-8 mt-sm-16">
      <div
        class="mb-4 mb-sm-5 d-flex align-center"
        v-for="item in checkboxList"
        :key="item.id"
      >
        <PrimaryCheckbox
          :value="true"
          disabled
        />

        <div class="font-normal descriptionMigration--text">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/buttons/ToggleButton.vue'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox.vue'
import TooltipAction from '@/components/tooltip/TooltipAction.vue'


const buttons = {
  allData: {
    id: 1,
    name: 'All Data'
  },
  account: {
    id: 2,
    name: 'Account'
  }
}

export default {
  name: 'MigrationGuideStepsCardOne',
  components: { TooltipAction, PrimaryCheckbox, ToggleButton },
  data: () => ({
    buttons: Object.values(buttons),
    activeTab: buttons.allData.id,
    checkboxList: [
      { id: 1, label: 'Profile Balance' },
      { id: 2, label: 'Credentials' },
      { id: 3, label: 'Cards' },
    ]
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width > 600
    }
  }
}
</script>

<style scoped lang="scss">

.migration-guide-steps-card-one {

  &__buttons {
    pointer-events: none;
  }
}
</style>