<template>
  <div class="mb-50 mb-sm-90 width--full d-flex flex-column align-center migration-guide-migrate">
    <div class="mb-6 titleMigration--text font-title-migration text-center">
      What Will Be Migrated?
    </div>

    <div class="font-normal descriptionMigration--text text-center migration-guide-migrate__description">
      Wondering what happens to all your info when you switch to Dialics 2.0? Don't worry! Here's the data we'll move for you:
    </div>

    <div class="mt-12 mt-sm-40 migration-guide-migrate-list">
      <div
        class="px-16 py-16 migration-guide-migrate-list__item"
        v-for="item in list"
        :key="item.id"
      >
        <div class="mb-8 d-flex align-center">
          <v-icon size="24" color="primary">$okIcon</v-icon>

          <div class="ml-4 titleMigration--text font-large">{{ item.title }}</div>
        </div>

        <div class="descriptionMigration--text font-regular">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MigrationGuideMigrate',
  data: () => ({
    list: [
      {
        id: 1,
        title: 'Campaigns',
        description: 'All your ongoing and past campaigns will be moved. You don\'t have to start over. Everything you’ve set up will be ready to go in Dialics 2.0.'
      },
      {
        id: 2,
        title: 'Numbers',
        description: 'After migration, you can only view Call logs and Reporting in the old version. You will not be able to manage or edit anything after the update.'
      },
      {
        id: 3,
        title: 'Destinations',
        description: 'The destination of your calls will not change. You won\'t have to re-route your calls.'
      },
      {
        id: 4,
        title: 'Buyers',
        description: 'The list of your buyers and users will be moved. You won’t have to enter buyer info again, making it easy to keep business going.'
      },
      {
        id: 5,
        title: 'Vendors',
        description: 'The information about your vendors and other users will be transferred. Keep working with the same people without any new setups.'
      },
      {
        id: 6,
        title: 'Balance',
        description: 'Any balance or credits you have in your account will move to Dialics 2.0. Your money stays with you. You won’t lose any credits when you upgrade.'
      },
    ]
  })
}
</script>

<style scoped lang="scss">

.migration-guide-migrate {

  &__description {
    max-width: 528px;
  }
}

.migration-guide-migrate-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }

  &__item {
    border-radius: 12px;
    border: 1px solid var(--v-borderGray-base);
  }
}
</style>