<template>
  <div class="mb-50 mb-sm-90 width--full d-flex flex-column justify-center">
    <div class="mb-30 mb-sm-40 titleMigration--text font-title-migration text-center">
      Your Easy 3-Step migration
    </div>

    <template v-for="item in items">
      <MigrationGuideStepsCard
        :item="item"
        :key="item.id"
      >
        <template #name-1>
          <MigrationGuideStepsCardOne/>
        </template>

        <template #name-2>
          <MigrationGuideStepsCardTwo/>
        </template>

        <template #name-3>
          <MigrationGuideStepsCardThree/>
        </template>
      </MigrationGuideStepsCard>
    </template>

    <div class="d-flex justify-center">
      <ActionButton
        class="mt-30 mt-sm-40"
        @click="goToStartMigration"
      >
        Start migration
      </ActionButton>
    </div>
  </div>
</template>

<script>
import MigrationGuideStepsCard
  from '@/views/settings/MigrationGuide/sections/MigrationGuideSteps/MigrationGuideStepsCard.vue'
import ActionButton from '@/components/buttons/ActionButton.vue'

import MigrationGuideStepsCardTwo
  from '@/views/settings/MigrationGuide/sections/MigrationGuideSteps/MigrationGuideStepsCardTwo.vue'
import MigrationGuideStepsCardThree
  from '@/views/settings/MigrationGuide/sections/MigrationGuideSteps/MigrationGuideStepsCardThree.vue'
import MigrationGuideStepsCardOne
  from '@/views/settings/MigrationGuide/sections/MigrationGuideSteps/MigrationGuideStepsCardOne.vue'

export default {
  name: 'MigrationGuideSteps',
  components: { MigrationGuideStepsCardOne, MigrationGuideStepsCardThree, MigrationGuideStepsCardTwo, ActionButton, MigrationGuideStepsCard },
  data: () => ({
    items: [
      {
        id: 1,
        title: 'Read, Choose, Confirm',
        description: 'Read our quick guide. Choose to move all data or start fresh. Confirm your choice.'
      },
      {
        id: 2,
        title: 'Migration In Action',
        description: 'We move your data for you. It will take about 20 minutes.'
      },
      {
        id: 3,
        title: 'Welcome to Dialics 2.0!',
        description: 'See a quick summary of what\'s moved and what’s new. Enjoy Dialics 2.0!'
      }
    ]
  }),
  methods: {
    goToStartMigration() {
      this.$router.push({ name: 'MigrationProcess' })
    }
  }
}
</script>